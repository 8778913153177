.container {
  min-height: 300px;
  background-color: white;
  margin-right:20px;
  th[class*="ant-table-cell"] {
    padding: 8px 5px 8px 5px !important;
    height: 16px !important;
    line-height: 20px !important;
    text-align: center !important;
    font-stretch: expanded !important;
    background-color: #fff !important;
    border-radius: 0px !important;
  }
  td[class*="ant-table-cell"] {
    padding: 3px 1px 3px 1px !important;
    text-align: center;
    font-stretch: expanded !important;
    font-family: Arial, Helvetica, sans-serif;
    border: none;
  }
  thead[class*="antd-table-thead"] {
    padding: 0px;
    border-radius: 0px !important;
    border: none;
    border-color: #fff !important;
    text-align: center;
  }
  tr[class*="antd-table-thead"] {
    padding: 0px;
    border: none;
    border-color: #fff !important;
    text-align: center;
  }
  tr[class*="antd-table-row"] {
    padding: 0px !important;
    border: none !important;
    border-color: #fff !important;
    text-align: center !important;
  }
  tr[class*="antd-table-row"] {
    padding: 0px !important;
    border: none !important;
    border-color: #fff !important;
    text-align: center !important;
  }
  th[class*="antd-table-thead"] {
    padding: 0px;
    border: none;
    border-color: #fff !important;
    text-align: center;
  }
  tr[class*="antd-table-body"] {
    padding: 0px;
    border-color: #fff !important;
    text-align: center;
  }
  th[class*="antd-table-body"] {
    padding: 0px;
    border-color: #fff !important;
    text-align: center;
  }
  div[class*="ant-table-header"] {
    border-radius: 0px !important;
    border-color: #fff !important;
    padding: 0px !important;
    border: none !important;
  }
  tr[class*="ant-table-header"] {
    border-radius: 0px !important;
    border-color: #fff !important;
    padding: 0px !important;
    border: none !important;
  }
  thead[class*="ant-table-thead"] > tr > th {
    border: none;
    ::before{
      background-color: #fff;
    }
  }
  thead[class*="ant-table-thead"] > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    border: none;
    
      background-color: #fff;
    
  }
  tbody[class*="ant-table-tbody"] > tr > td {
    border-color: gray;
  }
  span[class*='ant-table-column-sorter']{
    display: none;
  }
}
.customTable .ant-table-thead > tr > th {
  border: none;
  border-color: #fff !important;
  border-radius: 0px !important;
}
.mobileranking {
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  padding-left: 10px; 
  display:flex;
  justify-content: space-between;
}
.desktopranking{
  
  color: #000000;
  padding-left: 5px;
  font-size: 18px; 
  font-weight: 700;
  text-align: left;
  display:flex;
  justify-content: space-between;
}
.ranking {
  margin-right: 15px;
}
.rank {
  margin-right:15px; 
  font-weight:700;
  width: 15px;
  // background-color: red;
  display: inline-block;
}
.name {
  margin-right: 15px;
}
.mobilenamecontainer {
  text-align: left;
   font-weight: 300; 
   color : #000;
   font-size:10px;
   white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left:15px;
}
.desktopnamecontainer {
  text-align: left;
   font-weight: 500;
    color: #000000;
    padding-left:10px;
    white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

}
.hashtag {
  font-size:10px;
}
.hashtagcontainer {
  display:inline-block;
  width:25px;
}
.mobiletableheader{
  font-weight: 700;
   color: #000;
   text-align:center;


}
.desktoptableheader{
  font-weight: 700;
   color: #000;

}
.statsjersey {
  display:inline-block;
  width: 25px;
}
.tier {
  font-weight: 700;
  color: #000;
}
.mobilename{
  display:flex;
  justify-content:flex-start;
  color: #000000;
   font-size: 12px; 
   font-weight: 500;
   text-align: left;
   padding-left:10px;
}
.mobiledesk{
  display:flex;
  justify-content:flex-start;
  color: #000000;
  padding-left:5px;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
}
.mob{
  font-size: 12px;
  text-align:center;
}

.mobilestatsgp {
  font-size: 12px;
}
.padding{
  padding: 8px;
}
