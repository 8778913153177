.container {
    th[class*="ant-table-cell"] {
      padding: 8px 5px 8px 5px !important;
      height: 16px !important;
      line-height: 20px !important;
      text-align: center !important;
      font-stretch: expanded !important;
      background-color: #fff !important;
      border-radius: 0px !important;
    }
    td[class*="ant-table-cell"] {
      padding: 0px 1px 0px 1px !important;
      text-align: center;
      font-stretch: expanded !important;
      font-family: Arial, Helvetica, sans-serif;
      border: none;
    }
    thead[class*="antd-table-thead"] {
      padding: 0px;
      border-radius: 0px !important;
      border: none;
      border-color: #fff !important;
      text-align: center;
    }
    tr[class*="antd-table-thead"] {
      padding: 0px;
      border: none;
      border-color: #fff !important;
      text-align: center;
    }
    tr[class*="antd-table-row"] {
      padding: 0px !important;
      border: none !important;
      border-color: #fff !important;
      text-align: center !important;
    }
    tr[class*="antd-table-row"] {
      padding: 0px !important;
      border: none !important;
      border-color: #fff !important;
      text-align: center !important;
    }
    th[class*="antd-table-thead"] {
      padding: 0px;
      border: none;
      border-color: #fff !important;
      text-align: center;
    }
    tr[class*="antd-table-body"] {
      padding: 0px;
      border-color: #fff !important;
      text-align: center;
    }
    th[class*="antd-table-body"] {
      padding: 0px;
      border-color: #fff !important;
      text-align: center;
    }
    div[class*="ant-table-header"] {
      border-radius: 0px !important;
      border-color: #fff !important;
      padding: 0px !important;
      border: none !important;
    }
    tr[class*="ant-table-header"] {
      border-radius: 0px !important;
      border-color: #fff !important;
      padding: 0px !important;
      border: none !important;
    }
    thead[class*="ant-table-thead"] > tr > th {
      border: none;
      ::before{
        background-color: #fff;
      }
    }
    thead[class*="ant-table-thead"] > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      border: none;
      
        background-color: #fff;
      
    }
    tbody[class*="ant-table-tbody"] > tr > td {
      // border-color: gray;
    }
    
    .custom-row:hover td {
      background: none !important;
    }

    span[class*='ant-table-column-sorter']{
      display: none;
    }
  }
  .customTable .ant-table-thead > tr > th {
    border: none;
    border-color: #fff !important;
    border-radius: 0px !important;
  }
  .score{
    font-size: 14px;
    font-weight: 500;
    color: #000;
    display: inline-block;
  }
  .tablescore{
    font-size: 20px;
    font-weight: 700;
    color: #000;
    margin-right: 25px;
    display: inline-block;
  }
  .standingcolumn{
    color: #000000;
    font-size: 12px;
    font-weight: 500;
  }
  .columns{
    color: #000000;
    font-size: 18px;
    font-weight: 700;
  }
  .stats{
    text-align: center;
    min-width: 100px;
  }
  .rowitem {
    height: 50px;
    max-height: 50px;
  }
  